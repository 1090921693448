
import { defineComponent } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "trainee-otp",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  props: ['trainee_name', 'trainee_mobile', 'trainee_email', 'otp'],
  data() {
    return {

      user_otp: "",
      trainee_data: {
          name: this.trainee_name,
          mobile: this.trainee_mobile,
          email: this.trainee_email,
          otp: this.otp,
      },
      loading: false,
    };
  },
  async created() {
  },
  methods: {
    async otpFormSubmit() {

      if(this.trainee_data.otp == this.user_otp){
        this.loading = false;
        
        //this.$router.push("/trainee-register?");
        this.$router.push({
            name: 'Trainee Registration',
            params: {
                trainee_name: this.trainee_data.name,
                trainee_mobile: this.trainee_data.mobile,
                trainee_email: this.trainee_data.email,
                otp_match: 1,
            }
        });
      }else{
        this.loading = false;
        Swal.fire({
              title: "Please check your otp.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Close",
              customClass: {
                confirmButton: "btn btn-danger",
              },
        });
      }
    },
  },
  setup() {
    const TraineeOtpSchema = Yup.object().shape({
      otp: Yup.string().required().label("OTP"),
    });
    return {
      TraineeOtpSchema,
    };
  },
});
